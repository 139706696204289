import { useEffect, useState } from "react";

import { List } from "reactstrap";
import { Link } from "react-router-dom";
import { NavLink as NavLinkStrap } from "reactstrap";

// Import Static Images
import Logo from "../../components/common/Logo";
import Icon from "../../components/common/Icon";

import KlavioModal from "../../components/common/KlavioModal";

const HomePage = () => {
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [showKlavioModal, setShowKlavioModal] = useState(false);
  const toggleKlavioModal = () => setShowKlavioModal(!showKlavioModal);
  const [tableNumber, setTableNumber] = useState<string>("");

  useEffect(() => {
    const windowUrl = window.location.href;
    const params = new URL(windowUrl);

    const tableNumberFromPath = params.pathname.replace("/", "").replace(/\D/g, "");
    const _tableNumber = tableNumberFromPath !== "" ? tableNumberFromPath : "0";
    const _isError = parseInt(_tableNumber) <= 0 ? true : false;

    if (!_isError) {
      setTableNumber(_tableNumber);
    }

    setIsError(_isError);
    setIsLoading(false);
  }, []);

  if (isLoading) {
    return (
      <div className="c-body__wrap">
        <div className="u-page__loader">
          <div className="loader"></div>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="c-body__wrap">
        <div className="c-home">
          <div className="c-home__wrap">
            <a
              href="https://vivamelbourne.com.au"
              target="_blank"
              className="c-home__logo d-block flex-shrink-0"
              rel="noreferrer"
            >
              <Logo />
            </a>

            {isError && (
              <div className="u-error">
                <p className="fw-bold text-uppercase mb-4">Invalid Table</p>
              </div>
            )}

            {!isError && (
              <List className="c-home__menu">
                <li className="c-home__menu__items">
                  <NavLinkStrap
                    tag={Link}
                    to={`https://viva.yourorder.io/#!/menu?service=dinein&table_group=7&number=${tableNumber}`}
                    target="_blank"
                    className="c-home__menu__link"
                  >
                    Order Online
                  </NavLinkStrap>
                </li>
                <li className="c-home__menu__items">
                  <NavLinkStrap
                    tag={Link}
                    to="https://vivamelbourne.com.au/viva-menu/"
                    target="_blank"
                    className="c-home__menu__link"
                  >
                    Food & Drinks Menu
                  </NavLinkStrap>
                </li>
                <li className="c-home__menu__items">
                  <NavLinkStrap
                    tag={Link}
                    to="https://vivamelbourne.com.au/viva-program/"
                    target="_blank"
                    className="c-home__menu__link"
                  >
                    Show Program
                  </NavLinkStrap>
                </li>
                <li className="c-home__menu__items">
                  <NavLinkStrap
                    tag={Link}
                    to="https://vivamelbourne.com.au/viva-merchandise/"
                    target="_blank"
                    className="c-home__menu__link"
                  >
                    Merchandise
                  </NavLinkStrap>
                </li>
              </List>
            )}

            <div className="w-100 u-viva__message pt-3">
              <p className="fw-bold text-uppercase mb-0">
                <span>Join Viva VIP For Exclusive</span> <span>Invitations to VIP Events & More</span>
              </p>
              <div className="w-100 py-3 px-4">
                <button
                  type="button"
                  className="w-100 c-home__menu__link fs-18 u-form-submit"
                  onClick={() => {
                    toggleKlavioModal();
                    setTimeout(() => {
                      (window as any)._klOnsite.push(["openForm", "SewzHR"]);
                    }, 500);
                  }}
                >
                  JOIN NOW
                </button>
              </div>
            </div>

            <div className="w-100 viva__social pt-1">
              <NavLinkStrap
                className="viva__social__link"
                tag={Link}
                to="https://www.facebook.com/vivamelbourne"
                target="_blank"
              >
                <span className="size-20px p-0 flex-shrink-0 mx-auto">
                  <Icon name="Facebook" fill="currentColor" size="20" role="button" />
                </span>
              </NavLinkStrap>
              <NavLinkStrap
                className="viva__social__link"
                tag={Link}
                to="https://www.instagram.com/viva_melbourne/"
                target="_blank"
              >
                <span className="size-20px p-0 flex-shrink-0 mx-auto">
                  <Icon name="Instagram" fill="currentColor" size="20" role="button" />
                </span>
              </NavLinkStrap>
              <NavLinkStrap
                className="viva__social__link"
                tag={Link}
                to="https://www.tiktok.com/@viva_melbourne"
                target="_blank"
              >
                <span className="size-20px p-0 flex-shrink-0 mx-auto">
                  <Icon name="TikTok" fill="currentColor" size="20" role="button" />
                </span>
              </NavLinkStrap>
              <NavLinkStrap className="viva__social__link" tag={Link} to="mailto: info@vivamelbourne.com.au">
                <span className="size-20px p-0 flex-shrink-0 mx-auto">
                  <Icon name="Envelope" fill="currentColor" size="20" role="button" />
                </span>
              </NavLinkStrap>
            </div>
          </div>
        </div>
      </div>

      <KlavioModal isOpen={showKlavioModal} modalToggler={setShowKlavioModal} />
    </>
  );
};

export default HomePage;
