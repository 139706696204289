import { Modal, ModalBody, ModalHeader } from "reactstrap";

interface Props {
  isOpen: boolean;
  modalToggler: (isOpen: boolean) => void;
}

function KlavioModal({ isOpen, modalToggler }: Props) {
  return (
    <Modal
      isOpen={isOpen}
      toggle={() => modalToggler(!isOpen)}
      className="modal-dialog-centered modal-klavio animate__animated animate__zoomIn animate__fast"
      fade={false}
      onClosed={() => {
        window.location.reload();
      }}
    >
      <div className="modal-content-wrapper">
        <ModalHeader toggle={() => modalToggler(!isOpen)} className="z-3">
          JOIN VIVA VIP
        </ModalHeader>
        <ModalBody className="pt-0">
          <div className="klaviyo-form-SewzHR"></div>
        </ModalBody>
      </div>
    </Modal>
  );
}

export default KlavioModal;
