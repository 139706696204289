const Icons = {
  Facebook: () => (
    <path d="M6.1 9h-2c-.3 0-.4-.1-.4-.4V6.2c0-.3.1-.4.4-.4h2V4c0-.8.1-1.6.5-2.3C7 1 7.6.5 8.4.2c.5-.2 1-.3 1.5-.3h2c.3 0 .4.1.4.4v2.3c0 .3-.1.4-.4.4h-1.6c-.5 0-.8.3-.8.8v1.8h2.3c.3 0 .4.1.4.4v2.4c0 .3-.1.4-.4.4H9.5v6.5c0 .3-.1.5-.5.5H6.5c-.3 0-.4-.1-.4-.4V8.9Z" />
  ),
  Instagram: () => (
    <>
      <path d="M16 4.7c0-.9-.2-1.4-.4-1.9s-.5-1-.9-1.4c-.4-.4-.9-.7-1.4-.9-.5-.2-1.1-.3-1.9-.4H4.8c-.9 0-1.4.2-1.9.4s-1 .5-1.4.9c-.4.4-.7.9-.9 1.4-.2.5-.3 1.1-.4 1.9C0 5.6 0 5.8 0 8v3.3c0 .9.2 1.4.4 1.9s.5 1 .9 1.4c.4.4.9.7 1.4.9.5.2 1.1.3 1.9.4h6.6c.9 0 1.4-.2 1.9-.4 1.1-.4 1.9-1.3 2.3-2.3.2-.5.3-1.1.4-1.9V4.7Zm-1.5 6.5c0 .8-.2 1.2-.3 1.5-.3.7-.8 1.3-1.5 1.5-.3.1-.7.2-1.5.3H4.8c-.8 0-1.2-.2-1.5-.3-.3-.1-.7-.3-.9-.6-.3-.3-.5-.6-.6-.9-.1-.3-.2-.7-.3-1.5V4.8c0-.8.2-1.2.3-1.5.1-.3.3-.7.6-.9.3-.3.6-.5.9-.6.3-.1.7-.2 1.5-.3h6.4c.8 0 1.2.2 1.5.3.3.1.7.3.9.6.3.3.5.6.6.9.1.3.2.7.3 1.5v6.4Z" />
      <path d="M8 3.9C5.7 3.9 3.9 5.7 3.9 8s1.8 4.1 4.1 4.1 4.1-1.8 4.1-4.1S10.3 3.9 8 3.9Zm0 6.8c-1.5 0-2.7-1.2-2.7-2.7S6.5 5.3 8 5.3s2.7 1.2 2.7 2.7-1.2 2.7-2.7 2.7Zm5.2-7c0 .5-.4 1-1 1s-1-.4-1-1 .4-1 1-1 1 .4 1 1Z" />
    </>
  ),
  TikTok: () => (
    <path d="M15 4c-.9 0-1.8-.3-2.4-.8-.8-.6-1.3-1.4-1.5-2.4V0H8.5v11c0 1-.7 1.9-1.6 2.2-.3 0-.6.1-.9.1-.4 0-.8-.1-1.1-.3-.7-.4-1.1-1.1-1.2-2 0-1.3 1-2.4 2.4-2.4s.5 0 .7.1V6H6c-1.4 0-2.8.6-3.8 1.7-.7.8-1.2 1.9-1.2 3 0 1.4.4 2.8 1.5 3.8.1.1.3.3.5.4.9.7 1.9 1 3.1 1h.8c1.1-.2 2-.6 2.8-1.4.9-.9 1.5-2.2 1.5-3.5V5.2c.4.3.9.6 1.5.9.8.3 1.7.5 2.6.5V4Z" />
  ),
  Envelope: () => (
    <path d="M13.1 1.8H2.9C1.3 1.8 0 3.1 0 4.7v6.5c0 1.6 1.3 2.9 2.9 2.9h10.2c1.6 0 2.9-1.3 2.9-2.9V4.7c0-1.6-1.3-2.9-2.9-2.9Zm0 1.5c.3 0 .6.1.8.3L9.3 7.2c-.8.6-1.9.6-2.7 0L2.1 3.6c.2-.2.5-.3.8-.3h10.2Zm0 9.4H2.9c-.8 0-1.4-.6-1.5-1.5V4.9l4.3 3.3c.6.5 1.4.8 2.2.8.8 0 1.6-.3 2.2-.8l4.3-3.3v6.3c0 .8-.6 1.4-1.5 1.5Z" />
  ),
} as const;

export type IconNames = keyof typeof Icons;
export default Icons;
