import * as React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "animate.css";

// Import Pages
import HomePage from "../src/pages/home";

export default function App() {
  return (
    <Router>
      <section className="page-wrapper home-page">
        <main className="c-body p-0">
          <Routes>
            <Route path="/*" element={<HomePage />} />
          </Routes>
        </main>
      </section>
    </Router>
  );
}
